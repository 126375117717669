import { defineStore, acceptHMRUpdate } from 'pinia';
import { AuthConfig } from '@/config/auth';

export const useRegisterStore = defineStore({
	id: 'Register',
	state: () => ({}),
	getters: {},
	actions: {
		async register(organisation: string, first_name: string, last_name: string, email: string, password: string, password_confirmation: string) {
			if (organisation == '' || first_name == '' || last_name == '' || email == '' || password == '' || password_confirmation == '') {
				throw 'You need to complete all fields first!';
			}
			return await fetch(AuthConfig.register_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					organisation,
					first_name,
					last_name,
					email,
					password,
					password_confirmation,
				}),
			})
				.then((response: Response) => {
					if (!response.ok) {
						throw Error('There was an error registering your new account');
					}
					return response.json();
				})
				.then((response) => {
					return response;
				})
				.catch((error) => {
					throw error;
				});
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useRegisterStore, import.meta.hot));
}
