<template>
	<div class="min-h-screen bg-black flex">
		<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:w-1/2 lg:min-w-1/2">
			<div class="mx-auto w-full max-w-sm lg:w-120 -mt-32">
				<div>
					<img alt="Conduction PTY LTD" class="h-32 w-auto mx-auto mb-10 lg:mb-16 mt-" src="/logo.svg" />
					<h2 class="mt-6 text-3xl font-extrabold text-white text-center">Register a new account</h2>
				</div>

				<div class="mt-6">
					<div class="mt-6 relative">
						<div aria-hidden="true" class="absolute inset-0 flex items-center">
							<div class="w-full border-t border-gray-300" />
						</div>
						<div class="relative flex justify-center text-sm">
							<span class="px-2 bg-white text-gray-500"> </span>
						</div>
					</div>

					<div v-if="message" class="mt-6 relative text-red-500">
						<span>{{ message }}</span>
					</div>

					<div class="mt-6">
						<form class="space-y-4" @submit.prevent="register" @keyup.enter.prevent="register">
							<div>
								<label class="block text-sm font-medium text-white" for="organisation"> Organisation name</label>
								<div class="mt-1">
									<input
										id="organisation"
										v-model="organisation"
										class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
										name="organisation"
										required
										autofocus
										type="text"
									/>
								</div>
							</div>
							<div class="flex flex-row justify-between space-x-6">
								<div>
									<label class="block text-sm font-medium text-white" for="first_name"> First name </label>
									<div class="mt-1">
										<input
											id="first_name"
											v-model="first_name"
											class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
											name="first_name"
											required
											type="text"
										/>
									</div>
								</div>
								<div>
									<label class="block text-sm font-medium text-white" for="email"> Last name </label>
									<div class="mt-1">
										<input
											id="last_name"
											v-model="last_name"
											class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
											name="last_name"
											required
											type="text"
										/>
									</div>
								</div>
							</div>
							<div>
								<label class="block text-sm font-medium text-white" for="email"> Email address </label>
								<div class="mt-1">
									<input
										id="email"
										v-model="email"
										autocomplete="email"
										class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
										name="email"
										required
										type="email"
									/>
								</div>
							</div>
							<div class="flex flex-row justify-between space-x-6">
								<div class="space-y-1">
									<label class="block text-sm font-medium text-white" for="password"> Password </label>
									<div class="mt-1">
										<input
											id="password"
											v-model="password"
											autocomplete="current-password"
											class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
											name="password"
											required
											type="password"
										/>
									</div>
								</div>

								<div class="space-y-1">
									<label class="block text-sm font-medium text-white" for="password_confirmation"> Confirm password </label>
									<div class="mt-1">
										<input
											id="password_confirmation"
											v-model="password_confirmation"
											autocomplete="confirm-password"
											class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
											name="password_confirmation"
											required
											type="password"
										/>
									</div>
								</div>
							</div>

							<div class="pt-7">
								<button
									class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
									type="submit"
								>
									Register new account
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<div class="hidden lg:block relative w-0 flex-1">
			<img alt="" class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1493612276216-ee3925520721?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref } from 'vue';
	import { useAuthStore } from '@modules/auth/store';
	import { useRouter } from 'vue-router';
	import { useUserStore } from '@modules/user/store';
	import { useRegisterStore } from '@modules/register/store';

	const authStore = useAuthStore();
	const registerStore = useRegisterStore();
	const router = useRouter();

	//Send to dashboard if they are already authenticated
	if (authStore.isAuthenticated) {
		router.push({
			name: 'dashboard',
		});
	}

	const email = ref('');
	const password = ref('');
	const password_confirmation = ref('');
	const organisation = ref('');
	const first_name = ref('');
	const last_name = ref('');

	const message = ref('');

	const register = () => {
		registerStore
			.register(organisation.value, first_name.value, last_name.value, email.value, password.value, password_confirmation.value)
			.then(() => {
				const userStore = useUserStore();
				userStore.getLoggedInUser();
				router.push({
					name: 'dashboard',
				});
			})
			.catch((error) => {
				message.value = error.message;
				if (error.message == 'Failed to fetch') {
					message.value = 'Sorry! It looks like we are having some problems!';
				}
			});
	};
</script>
